import { PageProps } from 'gatsby';
import { client } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Button from '../../../components/Button';
import CMSView from '../../../components/View';
import TemplateAdmin from '../../../templates/admin';
import requireUser, { UserProps } from '../../../utils/requireUser';

const AdminCollections: FC<PageProps & UserProps> = ({ location, user }) => {
  const [collections, setCollections] = useState<any[]>();

  useEffect(() => {
    let isMounted = true;
    client
      .collections()
      .retrieve()
      .then(newCollections => {
        if (isMounted) {
          setCollections(newCollections);
        }

        return null;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <TemplateAdmin>
      <CMSView>
        <div className="flex justify-end">
          <Button to="/admin/collections/create/">
            Créer une nouvelle collection
          </Button>
        </div>

        {collections && (
          <ul>
            {collections.map(({ name, num_documents }) => (
              <li key={name}>
                <Button to={`/admin/collections/${name}/`} variant="link">
                  {name}
                </Button>
                ({num_documents})
              </li>
            ))}
          </ul>
        )}
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(AdminCollections, 'admin');
